import { debounce } from "debounce";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

let remoteBannerCache;

//
// https://javascript.info/task/delay-promise
//
function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

//
// https://hackernoon.com/functional-javascript-resolving-promises-sequentially-7aac18c4431e
//
function promiseSerial(funcs) {
    return funcs.reduce(
        (promise, func) => promise.then(result => func().then(Array.prototype.concat.bind(result))),
        Promise.resolve([]),
    );
}

function getBanners({
    scope = 'body',
    pageType = '',
}) {
    return $(scope).find(`[data-${APPUID_DASHCASE}]`).get().filter(el => {
        const { delay, pageType: ifPageType, urlMatch: ifUrlMatch, id, expire, disableText, swal } = JSON.parse($(el).data(APPUID_DASHCASE).replace(/'/g, '"') || '{}');

        if (!ifPageType || ifPageType === pageType) {
            if (!ifUrlMatch || window.location.href.match(new RegExp(ifUrlMatch))) {
                $(el).data(`${APPUID_DASHCASE}-delay`, delay);
                $(el).data(`${APPUID_DASHCASE}-id`, id);
                $(el).data(`${APPUID_DASHCASE}-expire`, expire);
                $(el).data(`${APPUID_DASHCASE}-disable-text`, disableText);
                $(el).data(`${APPUID_DASHCASE}-swal`, swal);
                return true;
            }
        }

        return false;
    });
}

/**
 * @param {Object}
 * @returns false|Array array of HTMLElement
 */
async function loadRemoteBanners({
    template = 'common/footer',
    ...settings
}) {
    if (remoteBannerCache) {
        return getBanners({ ...settings, scope: remoteBannerCache });
    } else {
        return await new Promise(resolve => {
            stencilUtils.api.getPage('/search.php', { template }, (err, resp) => {
                if (err) {
                    return resolve(false);
                }
                remoteBannerCache = resp;
                resolve(getBanners({ ...settings, scope: remoteBannerCache }));
            });
        })
    }
}

function getLocalBanners(settings) {
    return getBanners(settings);
}

function isExpired(id) {
    return !Cookies.get(`${APPUID}_ID_${id}`);
}

function setExpire(id, sec) {
    const expire = new Date(new Date().getTime() + sec * 1000);
    Cookies.set(`${APPUID}_ID_${id}`, 1, { expires: expire });
}

async function ready(settings) {
    $(`[data-${APPUID_DASHCASE}]`).hide();
    const { pageType = '' } = settings;
    const banners = [...getLocalBanners(settings), ...(pageType !== 'search' ? await loadRemoteBanners(settings) : [])];
    if (!banners || banners.length === 0) {
        return;
    }

    await promiseSerial(banners.map(el => async () => {
        const sec = Number($(el).data(`${APPUID_DASHCASE}-delay`) || 0) * 1000;
        const id = $(el).data(`${APPUID_DASHCASE}-id`);
        const expire = $(el).data(`${APPUID_DASHCASE}-expire`);
        const disableText = id && expire ? ($(el).data(`${APPUID_DASHCASE}-disable-text`) || "Don't show again") : '';
        const swalConfig = $(el).data(`${APPUID_DASHCASE}-swal`) || {};
        if (!id || isExpired(id, expire)) {
            const footer = disableText ? `
                <div class="form-field" style="margin:0">
                    <input type="checkbox" class="form-checkbox" name="${APPUID_DASHCASE}-disable" id="${APPUID_DASHCASE}-disable" value="1" checked>
                    <label class="form-label" for="${APPUID_DASHCASE}-disable">${disableText}</label>
                </div>
                ` : '';
            await delay(sec);
            let disable;
            await Swal.fire({
                html: $(el).clone().show(),
                showCloseButton: true,
                showCancelButton: false,
                showConfirmButton: false,
                focusConfirm: false,
                footer,
                onClose: () => {
                    disable = $(`#${APPUID_DASHCASE}-disable`).prop('checked');
                },
                ...swalConfig,
            });
            if (disable) {
                setExpire(id, expire);
            }
        }
    }));
}

export default function initPopups(settings = {}) {
    $(document).ready(() => ready(settings));
    $('body').on('loaded.instantload', () => ready(settings));
}
